:root {
  --main-color: #ffc500;
  --dark-color: #ce9e00;
  --darker-color: #9e7900;
  --background-self-color: rgb(216, 212, 212);
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--dark-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--darker-color);
}

/* Margins and Paddings */
.top_60 {
  margin-top: 60px !important;
}

.top_30 {
  margin-top: 30px !important;
}

.top_40 {
  margin-top: 40px !important;
}

.top_50 {
  margin-top: 50px !important;
}

.mb_20 {
  margin-bottom: 20px !important;
}

.mb_30 {
  margin-bottom: 30px !important;
}

.p_30 {
  padding: 30px !important;
}

.pt_45 {
  padding-top: 45px !important;
}

.pb_45 {
  padding-bottom: 45px !important;
}

.p_50 {
  padding: 50px !important;
}

@media (max-width: 991px) {
  .top_60 {
    margin-top: 30px !important;
  }
}

.a_nostyles {
  text-decoration: none;
  color: inherit;
}
/* End of Margins and Paddings */

.container_shadow {
  box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
}

.main-content {
  background-color: var(--background-self-color);
  min-height: 100vh;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
}

.section_title {
  position: relative;
  display: inline-block;
  padding: 0 3px;
}

.section_title .section_title_text {
  font-size: 18px;
  font-weight: 600;
  position: relative;
  margin: 0;
}

.section_title span {
  position: absolute;
  height: 7px;
  width: 100%;
  bottom: 0;
  opacity: 0.4;
  left: 0;
  background-color: var(--main-color);
}

.section {
  padding-left: 30px;
  padding-right: 30px;
}

.graybg {
  background-color: var(--background-self-color);
}